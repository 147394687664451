import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import ButtonGroup from '../components/ui/ButtonGroup';
import RadioButtonGroup from '../components/ui/RadioButtonGroup';
import RadioButton from '../components/ui/RadioButton';
import StepperLayout from '../components/layouts/StepperLayout';
import {
  getBillPresenceType,
  getHasControlledLoad,
  getHasSolarSystem,
  setHasSolarSystem,
  yesNoToBool,
} from '../utils/localStorage/localStorageFunctions';
import { YesNoType } from '../utils/localStorage/localStorageTypes';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';
import InfoMessage from '../components/ui/InfoMessage';

const SolarInstalled = () => {
  const [initialFormValues] = useState((): { solarInstalled: YesNoType | ''} => {
    const hasSolarSystemLS = getHasSolarSystem();
    return { solarInstalled: hasSolarSystemLS || '' };
  });

  const handleNext = (values?: typeof initialFormValues) => {
    const solarInstalled = values?.solarInstalled as YesNoType;
    const billType = getBillPresenceType();

    if (solarInstalled) {
      setHasSolarSystem(solarInstalled);
    } else {
      setHasSolarSystem(null);
    }

    if (yesNoToBool(solarInstalled)) {
      if (billType === 'paperBill') {
        navigateAndSetActiveStep(StepUrl.URL_SOLAR_INPUT);
        return;
      }
      navigateAndSetActiveStep(StepUrl.URL_SOLAR_SYSTEM_DETAILS);
      return;
    }

    navigateAndSetActiveStep(StepUrl.URL_RETAILER_CHOICE);
  };

  const handleBack = () => {
    const billType = getBillPresenceType();
    if (billType === 'pdfBill') {
      navigateAndSetActiveStep(StepUrl.URL_BILL_TYPE);
      return;
    }

    if (billType === 'noBill') {
      navigateAndSetActiveStep(StepUrl.URL_CONTROLLED_LOAD);
      return;
    }

    const hasControlledLoad = getHasControlledLoad();
    if (yesNoToBool(hasControlledLoad)) {
      navigateAndSetActiveStep(StepUrl.URL_CONTROLLED_LOAD_USAGE);
      return;
    }

    navigateAndSetActiveStep(StepUrl.URL_CONTROLLED_LOAD);
  };

  usePreloadPageData(
    StepUrl.URL_SOLAR_INPUT,
    StepUrl.URL_SOLAR_SYSTEM_DETAILS,
    StepUrl.URL_RETAILER_CHOICE,
    StepUrl.URL_BILL_TYPE,
    StepUrl.URL_CONTROLLED_LOAD,
    StepUrl.URL_CONTROLLED_LOAD_USAGE
  );

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={2}
        currentStep={9}
        pageUrl={StepUrl.URL_SOLAR_INSTALLED}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
              handleNext(values);
            }}
          >
            {(props) => (
              <Form>
                <h2 className="my-2">Do you have solar installed?</h2>
                <RadioButtonGroup
                  className="my-2"
                  errorText={props.errors.solarInstalled}
                >
                  <RadioButton
                    value="yes"
                    name="solarInstalled"
                    id="rad1"
                    required
                  >
                    Yes
                  </RadioButton>
                  <RadioButton value="no" name="solarInstalled" id="rad2">
                    No
                  </RadioButton>
                </RadioButtonGroup>
                <ButtonGroup className="mt-5">
                  <Button
                    type="button"
                    variant="tertiary"
                    onClick={() => {
                      handleNext();
                    }}
                  >
                    Skip
                  </Button>
                  <Button
                    type="submit"
                    disabled={props.values.solarInstalled === ''}
                  >
                    Next
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </div>
        <div className="my-3">
          <BackButton text="Back" onClick={handleBack} />
        </div>
        <div className="mt-2">
          <InfoMessage variant="warning">
            <strong>Note:</strong> The Huglo comparison app does not currently
            handle households with a residential battery. We are working hard to
            add this functionality. Apologies for any inconvenience.
          </InfoMessage>
        </div>
      </StepperLayout>
    </SEOLayout>
  );
};

export default SolarInstalled;
